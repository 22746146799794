.card-img-top {
    max-width: 160px;
    transform: translateX(50%);
}

/* .carousel {
    width: 25%;
} */
.red {
    color: red;
}

#back-button {
    margin: 0 15px 15px;
}

.subsection {
    padding-top: 15px;
}

.hidden {
    visibility: hidden;
}

#search-nav {
    width: 10000px;
    margin: 0 5%;
}

.address-form {
    margin: 20px 0;
}

.pie-chart {
    width: 50%;
    margin: 0 auto;
}

#profile-picture {
    width: 15%;
    float: right;
}